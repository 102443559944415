import React from 'react'
import facebook from './assets/New folder/facebook.png';
import instagram from './assets/New folder/instagram.png';
import './Home.css'
import youtube from './assets/New folder/youtube.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    <div className='footer'>
    <div className='sb_footer section_padding'>
    <div className='sb_footer-links'>
    <div className='sb_footer-links_div'>
    <h4 className='font'> Lets Keep in touch!</h4>
    <a href='/employer'>
        <p> Find us on any of this plaforms, we response 1-2 business days</p>
    </a>

    <div className='socialmedia'>
<a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL"><p className='social'> <img src={facebook} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'> <img src={instagram} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'  > <img src={youtube} alt="" /></p></a>
</div>
    </div>

    <div className='sb_footer-links_div'>
    <h4 className='font'> USEFUL LINK</h4>
    <a href='/'>
        <p> About us</p>
    </a>
    <a href='/employer'>
        <p> Blog</p>
    </a>
   
  
    <Link to="/check">
    <p> Book a Room</p>
    </Link>
 
    </div>

    
    <div className='sb_footer-links_div'>
    <h4 className='font'> OTHER RESOURCES</h4>
  
    <a href='/terms'>
       <Link to="/term" >
         <p>Term and Conditions</p>
    </Link>
    </a>
    <Link to="/term" >
         <p>Privacy & Policy</p>
    </Link>
    
        <Link to="/contact">
        <p> Contact Us</p>
    </Link>
   </div>


 

    
        
    </div>

    

   <hr></hr>
   <div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p> 
        @{new Date().getFullYear()}  © Rus Olive Lodge | Powered by <a href='https://www.instagram.com/techglacia?igsh=cXE1aGpvNXl4eXJ6'>TechGlacia</a>  
        <br/> 
    
        </p>
    </div>
    
   </div>
    </div>


    </div>
   
    </>
  )
}

export default Footer