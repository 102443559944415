import React, { useState } from "react";
import './Contact.css';
import './Home.css';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

export const Contact = () => {
  // State to store form data
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the body with HTML line breaks
    const emailBody = `
      Dear Rus Olive Team,  br 
      I hope this email finds you well.
      ${message} + <br> 
      Thank you for your time and assistance.   <br> 
      Best regards,  <br>
      Name: ${name} + <br>  
      Email: ${email} + <br> 
      Phone: ${phone} + <br> 
    `;

    // Encode the email body for the mailto link
    const subject = encodeURIComponent(`Message from ${name}`);
    const body = encodeURIComponent(emailBody);

    // Compose the mailto link
    const mailtoLink = `mailto:rusolivelodge@gmail.com?subject=${subject}&body=${body}`;

    // Open the mailto link (this will open the user's email client)
    window.location.href = mailtoLink;
  };

  return (
    <div className="body">
           <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Hotel in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Book your stay at Rus Olive Lodge in Skardu. Enjoy cozy rooms, peaceful ambiance, and affordable rates. Perfect for travelers, tourists, and business guests." 
        />

        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | Best Hotel in Skardu - Cozy & Affordable Stay" />
        <meta 
          property="og:description" 
          content="Looking for a place to stay in Skardu? Rus Olive Lodge offers a comfortable stay with affordable rooms, great service, and a prime location." 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Hotel in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      </Helmet>
      <div className="container">
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">
              We'd love to hear from you! Whether you have a question, need more information, or want to share feedback, feel free to reach out to us. Our team is here to help and will get back to you as soon as possible.
            </p>

            <div className="info">
              <div className="information">
                <img loading="lazy" src="/Homeimg/location.webp" className="icon" alt="" />
                <p>Rus Olive Lodge Near UOB Sundus Campus Skardu</p>
              </div>
              <div className="information">
                <img loading="lazy" src="/Homeimg/email.webp" className="icon" alt="" />
                <p>rusolivelodge@gmail.com</p>
              </div>
              <div className="information">
                <img  loading="lazy" src="/Homeimg/phone.webp" className="icon" alt="" />
                <p>+92-3705208893</p>
              </div>
            </div>
            fab fa-instagram
            <div className="social-media">
              <p>Connect with us:</p>
              <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA==">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title">Contact Us</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  placeholder="Your Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>

              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>

      {/* Footer */}
    {/* Footer */}
    <Footer/>
    </div>
  );
};
